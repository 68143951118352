<script setup>
// import { onMounted } from 'vue'
// import { useMeta } from 'quasar'
import isMobile from 'composables/isMobile.js'
// import diDNA from 'composables/diDNA.js'

// const { adsAreActive, pushToAdUnits } = diDNA()
// const metaData = {
//   meta: {
//     description: { name: 'description', content: 'A place to play for all ages and skill levels. USSSA empowers world-class events for athletes, parents, and fans.' }
//   }
// }
// useMeta(metaData)

isMobile()

// onMounted(() => {
//   if (adsAreActive()) {
//     pushToAdUnits({
//       id: 'BANNER_AD',
//       adUnitPath: '/154352941/e1a5b5b6e3255540',
//       size: [[970, 250]]
//     })
//   }
// })
</script>
<template>
  <!-- <div id="BANNER_AD" class="banner-ad column justify-center"
    v-if="adsAreActive()"
  /> -->
  <router-view v-slot="{ Component }">
    <transition
      appear
      mode="out-in"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
      :duration="200"
    >
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<style lang="sass">
.banner-ad
  height: 250px
  background: $snow-white-2
  text-align: center
  position: fixed
  margin: 0 auto
  top: 0
  left: 0
  right: 0
</style>
