import { defineStore } from 'pinia'

import { get, post, getCMS, getTSEndpoint } from '../../utils/apiUtils.js'

export const useCommonStore = defineStore('common', {
  state: () => ({
    availableSports: [],
    availableStates: [],
    availableParks: [],
    directors: [],
    competitiveClasses: [],
    currentTab: null,
    currentFilters: null,
    dataTitle: null,
    drawerOpen: false,
    hasDataError: false,
    hasZeroResults: false,
    isMobile: false,
    isLoadingData: false,
    showLaunchBanner: true,
    statures: [],
    resetDataTablePagination: false
  }),

  actions: {
    async fetchDataService (data) {
      const { endpoint, mutation, requestBody } = data
      try {
        const { data } = await post(endpoint, requestBody)
        mutation(data.currentPageResults)
        return true
      } catch (err) {
        throw new Error(err)
      }
    },

    async fetchFilterData (data) {
      const { endpoint, mutation, loadTotalCount, requestBody, errorMutation } = data
      // set loadingData state true
      this.setLoadingState(true)
      this.setZeroResults(false)
      this.setHasDataError(false)
      if (errorMutation) errorMutation(null)

      mutation([])
      try {
        const { data } = await post(endpoint, requestBody)

        await mutation(data.currentPageResults)
        await loadTotalCount(data.totalResultCount)

        if (data.currentPageResults.length === 0) {
          this.setZeroResults(true)
        }
      } catch (err) {
        if (errorMutation) errorMutation(err)
        this.setApiError()
        throw new Error(err)
      } finally {
        // set loadingData state false
        this.setLoadingState(false)
      }
    },

    async getDataService (data) {
      const { endpoint, mutation } = data
      try {
        const resp = await get(endpoint)
        mutation(resp.data)
        return true
      } catch (err) {
        throw new Error(err)
      }
    },

    async getDataTournamentSiteDetails (data) {
      const { endpoint, mutation, errorMutation } = data
      try {
        this.setLoadingState(true)
        this.setHasDataError(false)
        if (errorMutation) errorMutation(null)

        const { data } = await getTSEndpoint(endpoint)
        mutation(data)
        return true
      } catch (err) {
        if (errorMutation) errorMutation(err)

        await this.setApiError()
        throw new Error(err)
      } finally {
        // set loadingData state false
        this.setLoadingState(false)
      }
    },

    async getDataTypeDetails (data) {
      const { endpoint, mutation, errorMutation } = data
      try {
        this.setLoadingState(true)
        this.setHasDataError(false)
        if (errorMutation) errorMutation(null)

        const { data } = await get(endpoint)
        mutation(data)
        return true
      } catch (err) {
        if (errorMutation) errorMutation(err)

        await this.setApiError()
        throw new Error(err)
      } finally {
        // set loadingData state false
        this.setLoadingState(false)
      }
    },

    // TODO: REFACTOR TO USE GET DATA SERVICE
    async getDirector () {
      try {
        const sports = await get()
        this.loadAvailableSportsData(sports)
        return true
      } catch (err) {
        throw new Error(err)
      }
    },

    async getStaticPage (path) {
      try {
        const { data } = await getCMS(path)
        console.log(data)
      } catch (err) {

      }
    },

    hideLaunchBanner () {
      this.showLaunchBanner = false
    },

    loadAvailableSportsData (sports) {
      this.availableSports = sports
    },

    loadAvailableStatesData (states) {
      this.availableStates = states
    },

    loadCompetitiveClassesData (classes) {
      this.competitiveClasses = classes
    },

    loadStaturesData (statures) {
      this.statures = statures
    },

    loadAvailableDirectorsData (directors) {
      this.directors = directors
    },

    loadAvailableParksData (parks) {
      this.availableParks = parks
    },

    resetLaunchBanner () {
      this.showLaunchBanner = true
    },

    routeUserToAuth () {
      this.router.push({
        path: '/'
      })
        .then(() => {
          location.reload()
        })
    },

    setApiError () {
      this.setHasDataError(true)
    },

    setCurrentFilters (filters) {
      this.currentFilters = filters
    },

    setDrawerOpen (drawerOpen) {
      this.drawerOpen = drawerOpen
    },

    setDataTitle (title) {
      this.dataTitle = title
    },

    setLoadingState (isLoadingData) {
      this.isLoadingData = isLoadingData
    },

    setHasDataError (hasDataError) {
      this.hasDataError = hasDataError
    },

    setZeroResults (hasZeroResults) {
      this.hasZeroResults = hasZeroResults
    },

    setTab (tab) {
      this.currentTab = tab
    },

    setMobileState (isMobile) {
      this.isMobile = !!isMobile
    },

    setDataTablePagination (resetDataTablePagination) {
      this.resetDataTablePagination = resetDataTablePagination
    },

    async updateData (data) {}
  }
})
