import routeIdentifires from './routeIdentifires'

export default [
  // {
  //   path: '/siteDown',
  //   component: () => import('layouts/MainLayout.vue'),
  //   children: [
  //     { path: '', name: 'siteDown', component: () => import('pages/SiteDown.vue') }
  //   ]
  // },
  {
    path: '/brackets',
    component: () => import('layouts/MainLayout.vue'),
    meta: { layoutType: 'mainLayout' },
    children: [
      {
        path: '',
        name: 'brackets',
        component: () => import('src/pages/BracketsPage.vue')
      }
    ]
  },
  {
    path: '/home',
    component: () => import('layouts/MarketingLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('src/pages/HomePage.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', redirect: { name: 'home' } },
      {
        path: routeIdentifires.CONTACT.path,
        name: routeIdentifires.CONTACT.name,
        component: () => import('src/pages/ContactPage.vue')
      },
      {
        path: routeIdentifires.FAQs.path,
        name: routeIdentifires.FAQs.name,
        component: () => import('pages/FrequentlyAskedQuestions.vue')
      }
    ]
  },
  {
    path: routeIdentifires.ATHLETES.path,
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: routeIdentifires.ATHLETES.name,
        component: () => import('pages/athletes/AthletesLanding.vue')
      },
      {
        path: routeIdentifires.ATHLETE.path,
        name: routeIdentifires.ATHLETE.name,
        component: () => import('pages/athletes/AthleteDetails.vue')
      },
      {
        path: routeIdentifires.AGE_CALCULATOR.path,
        name: routeIdentifires.AGE_CALCULATOR.name,
        component: () => import('pages/athletes/AthleteAgeCalculator.vue')
      }
    ]
  },
  {
    path: routeIdentifires.EVENTS.path,
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: routeIdentifires.EVENTS.name,
        component: () => import('pages/events/EventsLanding.vue'),
        props: true
      },
      {
        path: routeIdentifires.EVENT_DETAILS.path,
        component: () => import('pages/events/EventDetails.vue'),
        children: [
          {
            name: routeIdentifires.EVENT_DETAILS.name,
            path: '',
            component: () => import('pages/events/EventDetailsLanding.vue'),
            props: true
          },
          {
            path: routeIdentifires.EVENT_DIVISIONS.path,
            name: routeIdentifires.EVENT_DIVISIONS.name,
            component: () => import('pages/events/EventDivisionsLanding.vue')
          }
        ]
      }
    ]
  },
  {
    path: routeIdentifires.TEAMS.path,
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: routeIdentifires.TEAMS.name,
        path: '',
        component: () => import('src/pages/teams/TeamsLanding.vue')
      },
      {
        name: routeIdentifires.TEAM_DETAILS.name,
        path: routeIdentifires.TEAM_DETAILS.path,
        component: () => import('src/pages/teams/TeamDetails.vue')
      }
    ]
  },
  {
    path: routeIdentifires.UNIVERSITY_LANDING.path,
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: routeIdentifires.UNIVERSITY_LANDING.name,
        component: () => import('pages/usssaUniversity/UniversityLanding.vue')
      },
      {
        name: routeIdentifires.UNIVERSITY_DETAILS.name,
        path: routeIdentifires.UNIVERSITY_DETAILS.path,
        component: () => import('pages/usssaUniversity/UniversityDetails.vue')
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404Page.vue')
  }
]
