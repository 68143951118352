import { defineStore } from 'pinia'

export const useFiltersStore = defineStore('filters', {
  state: () => ({
    athletes: {
      age: [],
      partialName: [],
      sport: [],
      state: []
    },
    events: {
      competitiveClasses: [],
      director: [],
      sport: [],
      statures: [],
      state: [],
      zipCode: [],
      range: [],
      searchRangeStart: [],
      searchRangeEnd: [],
      park: []
    },
    teams: {
      competitiveClasses: [],
      season: [],
      sport: [],
      state: []
    }
  }),

  actions: {
    setFilterValue (options) {
      const { filterType, isPushArray, prop, value } = options
      isPushArray
        ? this[filterType][prop].push(value)
        : this[filterType][prop] = value
    },
    resetFilters (filterType) {
      const filter = this[filterType]
      const keys = Object.keys(filter)

      keys.forEach(filterProp => {
        filter[filterProp] = []
      })
    }
  },
  persist: true
})
