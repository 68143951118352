import { dotCMSServicesApi, tournamentSitesApi, usssaServicesApi } from 'src/boot/axios'

// HTTP GET Request
export const get = (path) => {
  return usssaServicesApi.get(path)
}
// HTTP PATCH Request
export const patch = async (path, data) => {
  return usssaServicesApi.patch(path, data)
}
// HTTP POST Request
export const post = async (path, data) => {
  return usssaServicesApi.post(path, data)
}
// HTTP DELETE Request
export const del = async (path) => {
  return usssaServicesApi.delete(path)
}

// TEMP GET DOTCMS Request
export const getCMS = (path) => {
  return dotCMSServicesApi.get(path)
}

export const getTSEndpoint = (path) => {
  return tournamentSitesApi.get(path)
}
